<template>
  <div class="m-rule-cont">
    <h3>活动规则</h3>
   <p> 活动期间内，凡成包购买今麦郎“饮用天然水5L”促销装，即可参与“买整包 扫码赢红包 100%中奖”活动。</p>
   <b> 参与方式：</b>
   <p> 打开微信扫一扫，扫描卡片背面二维码，扫码后按照提示参与活动。</p>
   <b> 活动时间：</b>
   <p> 2022年6月1日00:00起至2023年9月30日18:00止。</p>
   <b> 奖项设置：</b>
   <p> 一等奖：微信红包2.88元（中奖率1%）；</p>
   <p> 二等奖：微信红包1.88元（中奖率5%）；</p>
   <p> 三等奖：微信红包0.68元（中奖率94%）；</p>
   <p> （综合中奖率100%）；</p>
   <p> 单个用户当日获得微信红包个数上限为10个。</p>
   <b> 兑奖说明：</b>
   <p> 微信现金红包为即时到账，您可在“零钱明细”中查询中奖金额，如有问题可咨询本次活动热线。</p>
   <b> 活动热线：</b>
   <p> 400-815-9210（活动期间周一至周五09:00-18:00，法定节假日除外）；</p>
   <p> 0319-5106999（活动期间周一至周五08:00-17:00，法定节假日除外）。</p>
   <b> 注意事项：</b>
   <p> 请在参与本活动前仔细阅读本规则中的各项条款，如果您参与了本活动，则今麦郎公司（以下简称主办方）认为您已理解并同意该规则中的各条款。</p>
   <p> 1.活动区域：全国（不包含港、澳、台）；</p>
   <p> 2.主办方对本次活动促销产品及其附属物（如产品包装、二维码等）的真实性和有效性有鉴定及确认权；</p>
   <p> 3.卡片背面二维码、奖品以实物为准，活动站点及相关宣传资料上提供的图片仅供参考；</p>
   <p> 4.本次促销活动，如遇不可抗力事件，包括但不限于地震、台风、水灾或其他自然灾害、火灾、爆炸事故、骚乱、战争、政府政策的改变、任何国家主管机关就此次活动做出任何判决、决定、命令，或未能合理控制的任何其他不可预见事件，主办方有权在不事先通知的前提下变更或取消本活动；</p>
   <p> 5.如果参与者在活动中使用任何不正当的手段参与活动，主办方有权在不事先通知的情况下取消其参与活动的资格，同时保留依法追究其法律责任的权利；</p>
   <p> 6.主办方保留对争议和异议的处理权利。主办方对于获奖者因参与本活动所发生的任何直接、间接费用和产生的任何损害不负任何责任；</p>
   <p> 7.本次活动由北京尚杰智选科技有限公司提供技术支持；</p>
   <p> 8.本规则的解释与适用，以及与规则有关的任何争议，均以中华人民共和国法律为依据。</p>
  </div>
</template>

<script>
export default {
  name: 'BaseActivityRules',
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.m-rule-cont {
  font-size: 24px;
  line-height: 34px;
  color: #313131;

  h3 {
    margin-bottom: 5px;
    font-size: 28px;
    text-align: center;
    font-weight: bold;
  }

  b {
    display: block;
    margin-top: 6px;
  }
}
</style>
